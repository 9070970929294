import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className='barlow-condensed-semibold'>macexx</h1>
        <h2 className='barlow-condensed-extralight'>IT Consultancy</h2>
        <p className='barlow-condensed-thin'>Coming soon!</p>
      </header>
    </div>
  );
}

export default App;
